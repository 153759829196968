<template>
  <div class="system">
    <page-list-container :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
      @onPageChange="onPageChange" @onSizeChange="onSizeChange">
      <div>
        <title-bar></title-bar>
        <cell v-for="(item, index) of dataArray" :key="index" :model="item" :index="index"
          :isSelected="isCellSelected(item)" @onSelectClick="onCellSelectClick"></cell>
      </div>
    </page-list-container>
  </div>
</template>

<script>
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";
import TitleBar from "@c/report/blood-oxygen-report/blood-oxygen-title-bar.vue";
import Cell from "@c/report/blood-oxygen-report/blood-oxygen-cell.vue";
import { DateTool } from "@js/date-tool.js";
import { downloadExportFile } from "@js/report-export-tool.js";

export default {
  components: {
    PageListContainer,
    TitleBar,
    Cell,
  },

  props: {
    componentDuration: Object,
  },

  data() {
    return {
      dataArray: [],
      total: 0,
      pageSize: undefined,
      currentPage: 1,
      selectedModel: null,
      keywords: undefined
    };
  },

  computed: {
    hospitalID() {
      return this.$store.state.selectedHospital.hospitalid;
    },
  },

  watch: {
    hospitalID() {
      this.currentPage = 1;
      this.getReportList();
    },

    componentDuration() {
      this.currentPage = 1;
      this.getReportList();
    }
  },

  mounted() {
    this.pageSize = bestPageSizeComputer()
    this.getReportList();
  },

  methods: {
    onCellSelectClick: function (model) {
      this.selectedModel = model;
    },

    //分页
    onPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getReportList();
    },

    onSizeChange(size) {
      this.pageSize = size
      this.currentPage = 1
      this.getReportList();
    },

    onSearchClick(keywords) {
      this.keywords = keywords;
      this.currentPage = 1;
      this.getReportList();
    },

    // method
    async getReportList() {
      this.selectedModel = null;
      const params = {
        "hospitalId": this.hospitalID == 0 ? null : this.hospitalID,
        "keyWord": this.keywords,
        "pageNum": this.currentPage,
        "pageSize": this.pageSize,
        "startTime": DateTool.timestampToStr(this.componentDuration.startTime, "Y-M-D h:m:s"),
        "endTime": DateTool.timestampToStr(this.componentDuration.endTime, "Y-M-D h:m:s")
      };

      try {
        const data = await this.$api.getBloodOxygenReportList(params);
        this.dataArray = data.list;
        this.total = data.total;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    isCellSelected(item) {
      return this.selectedModel?.id === item.id
    },
    //导出睡眠初筛报告
    async exportReport() {
      if (this.total === 0) {
        this.$toast.showRed('暂无数据')
        return
      }
      const params = {
        hospitalId: this.hospitalID == 0 ? null : this.hospitalID,
        keyWord: this.keywords,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        startTime: DateTool.timestampToStr(this.componentDuration.startTime, "Y-M-D h:m:s"),
        endTime: DateTool.timestampToStr(this.componentDuration.endTime, "Y-M-D h:m:s")
      };
      try {
        const response = await this.$api.exportBloodOxygenReport(params)
        console.log(response);
        downloadExportFile(response)
      } catch (e) {

      } finally {

      }
    },
  },
};
</script>


<style lang="scss" scoped>
.system {
  font-size: 19px;
  width: 100%;
}
</style>