<template>
  <div class="report">
    <div class="report-content">
      <filter-bar class="report-content-filter-bar" :itemArray="['睡眠报告', '睡眠初筛报告']"
        :initialSelectedIndex="selectedComponentIndex" keywordTitle="姓名或设备SN" hasDatePicker :initDuration="duration"
        v-model="keywords" @onItemSelected="onItemSelected" @onDurationSelected="onDurationSelected"
        @onSearchClick="onKeywordSearch">
        <button class="report-content-filter-bar-export" @click="onExportReport(selectedComponentIndex)">
          导出报告
        </button>
      </filter-bar>
      <component :is="currentComponent" ref="component" :componentDuration="componentDuration">
      </component>
    </div>
  </div>
</template>

<script>
import FilterBar from "@c/operation-log/common/filter-bar/filter-bar.vue";
import SleepReport from "@c/report/sleep-report/sleep-report.vue";
import BloodOxygenReport from "@c/report/blood-oxygen-report/blood-oxygen-report.vue";
export default {
  components: {
    FilterBar,
    SleepReport,
    BloodOxygenReport
  },

  data: function () {
    return {
      // duration: [this.getTimestamp(7), this.getTimestamp(0, true)],
      duration: undefined,
      keywords: "",
      exportParams: undefined,
      selectedComponentIndex: 0,
      compoentArray: ["sleep-report", "blood-oxygen-report"]
    };
  },

  methods: {
    getTimestamp(daysAgo, endOfDay = false) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

      const targetDate = new Date(currentDate);
      targetDate.setDate(currentDate.getDate() - daysAgo);

      if (endOfDay) {
        targetDate.setHours(23, 59, 59, 999); // Set to end of the day
      }

      return Math.floor(targetDate.getTime()); // Convert to seconds
    },
    onItemSelected(index) {
      this.selectedComponentIndex = index;
      this.duration = undefined;
      this.keywords = undefined
    },

    onExportReport(index) {
      let component = this.$refs.component;
      switch (index) {
        //导出睡眠报告
        case 0:
          component.exportReport()
          break;
        //导出睡眠初筛报告
        case 1:
          component.exportReport()
          break;
        default:
          break;
      }
    },

    onDurationSelected(duration) {
      this.duration = duration
    },

    onKeywordSearch(keyword) {
      this.$nextTick(() => { this.$refs.component.onSearchClick(keyword) })
    },
  },

  computed: {
    componentDuration() {
      if (this.duration) {
        return {
          startTime: this.duration[0] / 1000,
          endTime: this.duration[1] / 1000,
        };
      } else {
        return { startTime: null, endTime: null };
      }
    },

    currentComponent() {
      return this.compoentArray[this.selectedComponentIndex]
    }
  },
};
</script>

<style lang="scss" scoped>
.report {
  background-color: #eff2f7;
  width: 100%;

  &-content {
    background-color: white;
    border-radius: rem(2);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: rem(36);
    padding-right: rem(33);
    display: flex;
    flex-direction: column;
    align-items: center;

    &-filter-bar {
      width: 100%;

      &-export {
        color: white;
        background: #325FAC;
        font-size: 14px;
        border-radius: 4px;
        width: 88px;
        height: 32px;
        margin-left: 74px;
      }
    }
  }
}
</style>
