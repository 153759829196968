class DateTool {
  static _formatNumber(n) {
    n = n.toString();
    return n[1] ? n : "0" + n;
  }

  static formatDate(date, format) {
    if (date) {
      var formateArr = ["Y", "M", "D", "h", "m", "s"];
      var returnArr = [];

      returnArr.push(date.getFullYear());
      returnArr.push(DateTool._formatNumber(date.getMonth() + 1));
      returnArr.push(DateTool._formatNumber(date.getDate()));

      returnArr.push(DateTool._formatNumber(date.getHours()));
      returnArr.push(DateTool._formatNumber(date.getMinutes()));
      returnArr.push(DateTool._formatNumber(date.getSeconds()));

      for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
      }
      return format;
    } else {
      return null
    }

  }

  //秒时间戳转换为字符串
  static timestampToStr(timestamp, format) {
    if (timestamp) {
      const date = new Date(timestamp * 1000);
      return DateTool.formatDate(date, format);
    } else {
      return null
    }

  }

  static millisecondTimestampToStr(timestamp, format) {
    if (timestamp) {
      const date = new Date(timestamp);
      return DateTool.formatDate(date, format);
    } else {
      return null
    }
  }

  //字符串转换为Date
  static strToDate(timeStr) {
    //timeStr是待转换时间字符串，格式：'2018-9-12 9:11:23'
    //为了兼容苹果，需先将字符串转换为'2018/9/11 9:11:23'
    if (timeStr) {
      let formattedTimeStr = timeStr.replace(/-/g, "/");
      let date = new Date(formattedTimeStr);
      return date;
    } else {
      return null
    }

  }

  //字符串转换为毫秒时间戳
  static strToMillisecondTimestamp(timeStr) {
    //timeStr是待转换时间字符串，格式：'2018-9-12 9:11:23'
    //为了兼容苹果，需先将字符串转换为'2018/9/11 9:11:23'
    let formattedTimeStr = timeStr.replace(/-/g, "/");
    let timestamp = Date.parse(formattedTimeStr);
    return timestamp;
  }

  //秒数转化为时分秒
  static formatSeconds(value) {
    var secondTime = parseInt(value) // 秒
    var minuteTime = 0 // 分
    var hourTime = 0 // 小时
    var dayTime = 0 // 天
    var result = ''

    if (secondTime == NaN) {
      result = null
    } else if (secondTime == 0) {
      result = '0分钟'
    } else if (secondTime < 60) {
      result = secondTime + '秒'
    } else {
      if (secondTime >= 60) { // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60)
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60)
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime >= 60) {
          // 获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60)
          // 获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60)
          if (hourTime >= 24) {
            // 获取天数， 获取小时除以24，得到整数天
            dayTime = parseInt(hourTime / 24)
            // 获取小时后取余小时，获取分钟除以24取余的分；
            hourTime = parseInt(hourTime % 24)
          }
        }
      }
      if (minuteTime > 0) {
        result = '' + minuteTime + '分钟' + result
      }
      if (hourTime > 0) {
        result = '' + parseInt(hourTime) + '小时' + result
      }
      if (dayTime > 0) {
        result = '' + parseInt(dayTime) + '天' + result
      }
    }
    return result
  }
}

export { DateTool };
