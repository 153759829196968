<template>
    <page-list-container class="sleep-report" :total="total" :pageSize="pageSize" :inputCurrentPage="currentPage"
        @onPageChange="onPageChange" @onSizeChange="onSizeChange">
        <item-bar :selectedCompletenessIndex="selectedCompletenessIndex" :selectedAHILevelIndex="selectedAHILevelIndex"
            @onAHILevelItemClick="onAHILevelItemClick" @onCompletenessItemClick="onCompletenessItemClick"></item-bar>
        <div class="sleep-report-list">
            <cell class="sleep-report-list-item" v-for="(item, index) of reportArray"
                :isSelected="selectedID === item.id" :key="index" :model="item" @onSelectClick="onCellSelectClick"
                @onDeleteReport="onDeleteReport">
            </cell>
        </div>
    </page-list-container>
</template>

<script>
import ItemBar from "@c/report/sleep-report/item-bar.vue";
import Cell from "@c/report/sleep-report/cell.vue";
import Pagination from "@c/common/pagination2.vue";
import PageListContainer from "@c/operation-log/common/paging-list/page-list-container.vue";

import { exportReport } from "@js/report-export-tool.js";
export default {
    components: {
        ItemBar,
        Cell,
        PageListContainer,
        Pagination,
    },

    props: {
        componentDuration: Object,
    },

    data: function () {
        return {
            //deviceList
            reportArray: [],
            total: undefined, // 显示总条数
            pageSize: undefined, // 每页显示条数
            currentPage: 1, // 当前的页数
            keywords: undefined,
            //item-bar
            selectedAHILevelIndex: 0,
            selectedCompletenessIndex: 0,
            //cell
            selectedID: undefined,


        };
    },

    mounted() {
        this.pageSize = bestPageSizeComputer()
        this.reportListRequest();
    },

    watch: {
        hospitalID: function () {
            this.currentPage = 1;
            this.selectedAHILevelIndex = 0;
            this.selectedCompletenessIndex = 0;
            this.reportListRequest();
        },

        componentDuration() {
            this.currentPage = 1;
            this.selectedAHILevelIndex = 0;
            this.selectedCompletenessIndex = 0;
            this.reportListRequest();
        }
    },

    methods: {
        exportReport() {
            const params = {
                startTime: this.componentDuration.startTime,
                endTime: this.componentDuration.endTime,
                keywords: this.keywords,
                ahi: this.ahi == 0 ? null : this.ahi,
                hospitalId: this.hospitalID == 0 ? null : this.hospitalID,
                complete: this.complete == 0 ? null : this.complete,
            };
            exportReport(params);
        },

        onAHILevelItemClick: function (index) {
            this.currentPage = 1;
            this.selectedAHILevelIndex = index;
            if (this.selectedAHILevelIndex == 0) {
                this.selectedCompletenessIndex = 0;
            } else {
                this.selectedCompletenessIndex = 1; //AHI是完整的报告
            }
            this.reportListRequest();
        },

        onCompletenessItemClick: function (index) {
            this.currentPage = 1;
            this.selectedCompletenessIndex = index;
            this.reportListRequest();
        },

        onCellSelectClick: function (id) {
            this.selectedID = id;
        },

        onSearchClick(keywords) {
            this.keywords = keywords;
            this.currentPage = 1;
            this.selectedAHILevelIndex = 0;
            this.selectedCompletenessIndex = 0;
            this.reportListRequest();
        },

        async onDeleteReport(id) {
            try {
                const params = {
                    "id": id
                }
                await this.$api.deleteRequest(params)
                this.reportListRequest();
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        //分页
        onPageChange(currentPage) {
            this.currentPage = currentPage;
            this.reportListRequest();
        },

        onSizeChange(size) {
            this.pageSize = size
            this.currentPage = 1
            this.reportListRequest();
        },

        async reportListRequest() {
            try {
                const params = {
                    "keyWords": this.keywords,
                    "pageNum": this.currentPage,
                    "hospitalId": this.hospitalID,
                    "pageSize": this.pageSize,
                    "startTime": this.componentDuration.startTime,
                    "endTime": this.componentDuration.endTime,
                    "complete": this.selectedCompletenessIndex,
                    "ahi": this.selectedAHILevelIndex
                }
                const response = await this.$api.getSleepReportPageList(params)
                this.total = response.total;
                this.reportArray = response.list;
            } catch (error) {
                this.$toast.showRed(error);
            }
        },
    },

    computed: {
        hospitalID: function () {
            return this.$store.state.selectedHospital.hospitalid;
        },

        ahi: function () {
            return getObjectValues(AHI_LEVEL_CODE)[this.selectedAHILevelIndex];
        },

        complete: function () {
            return getObjectValues(REPORT_COMPLETENESS_REQUEST_CODE)[
                this.selectedCompletenessIndex
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.sleep-report {
    width: 100%;
    box-sizing: border-box;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-list {
        width: 100%;
    }

    &-empty-info {
        color: rgba($color: black, $alpha: 0.85);
        font-size: rem(14);
        width: 100%;
        margin-top: rem(100);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-pagination {
        width: 100%;
        height: rem(90);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
