<template>
  <button class="cell-unit" @click="onClick">
    <img
      class="cell-unit-icon"
      :src="[isValid ? validIconSRC : inValidIconSRC]"
    />
    <p class="cell-unit-title" :class="{ disable: !isValid }">{{ title }}</p>
  </button>
</template>

<script>
export default {
  props: {
    title: String,
    validIconSRC: String,
    inValidIconSRC: String,
    isValid: Boolean,
  },

  methods: {
    onClick: function () {
        this.$emit("onClick")
    },
  },
};
</script>

<style lang="scss" scoped>


.cell-unit {
  display: flex;
  align-items: center;

  &-icon {
    width: rem(20);
    height: rem(16);
  }

  &-title {
    color: #325FAC;
    font-size: rem(14);
    line-height: rem(22);
    margin-left: rem(8);
  }

  .disable {
    color: #c1c1c1;
  }
}
</style>