import vue from "../../main.js";
import axios from "axios";
import { CacheTool } from "@js/cache-tool.js";
import { Loading } from "element-ui";

var loading = null;

export function exportReport(params) {
    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_URI_NEW,
        responseType: "blob",
        headers: {
            token: CacheTool.getLoginInfo().token,
            system:1,
        },
    });
    loading = Loading.service({
        background: "rgba(0,0,0,0.1)"
    });
    instance
        .post("v2/web/sleepReport/export", params)
        .then(async (res) => {
            try {
                const dataSize = res.data.size
                if (dataSize < 200) { //报错返回JSON字符串
                    const dataJSON = await res.data.text();
                    const dataModel = JSON.parse(dataJSON)
                    throw dataModel.msg
                } else {
                    let blob = new Blob([res.data], {
                        type: "applicatoin/octet-stream",
                    });
                    let _fileName = res.headers["content-disposition"].split("=")[1];
                    //创建link
                    const link = document.createElement("a");
                    link.style.display = "none";
                    const url = window.URL || window.webkitURL || window.moxURL; //兼容不同浏览器的URL对象
                    link.href = url.createObjectURL(blob);
                    link.setAttribute(
                        "download",
                        _fileName.substring(_fileName.lastIndexOf("_") + 1)
                    );
                    document.body.appendChild(link);
                    link.click();
                    //销毁url对象
                    loading.close();
                    setTimeout(() => {
                        document.body.removeChild(link);
                        url.revokeObjectURL(link.href);
                    }, 1000);

                }
            } catch (error) {
                loading.close();
                vue.$toast.showRed(`下载的文件出错：${error}`);
            }
        })
        .catch((error) => {
            loading.close();
            vue.$toast.showRed(error);
        });
}

export const downloadExportFile = (response) => {
    const contentDisposition = response.headers['content-disposition'];
    let filename = '';
    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            filename = decodeURIComponent(matches[1].replace(/['"]/g, '')).replace(/^utf-8/, '');
        }
    }
    // 创建一个临时的 <a> 标签，并设置下载属性
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    a.href = url;
    a.download = filename; // 设置下载的文件名
    a.click();
    // 清除临时 URL 对象
    window.URL.revokeObjectURL(url);
}