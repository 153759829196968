<template>
  <div class="cell" :class="{ selected: isSelected }" @click="onSelectClick">
    <div class="cell-unit name">{{ userName }}</div>
    <div class="cell-unit ahi" :style="{ color: ahiColor }">
      {{ ahiInfo }}
    </div>
    <div class="cell-unit sn">{{ model.sn }}</div>
    <div class="cell-unit create-time">{{ recordDay }}</div>
    <div class="cell-unit organization">{{ hospitalName }}</div>
    <div class="cell-unit completeness-out">
      <el-popover placement="top" width="350" :append-to-body="false" :popper-options="{
        positionFixed: true,
      }" ref="popover">
        <div class="popbox">

          <div class="popbox-monitorTime">
            <span>{{ monitorTime }}</span>
            <span style="color: #F45C50;" v-if="isLessThan4Hours">（&lt4小时）</span>
          </div>
          <div class="popbox-sleepTime">
            <span>{{ sleepTime }}</span>
            <span style="color: #F45C50;" v-if="isLessThan3Hours">（&lt3小时）</span>
          </div>
          <div class="popbox-create">
            {{ createTime }}
          </div>
        </div>
        <div slot="reference" class="cell-unit completeness" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter">
          <div class="cell-unit-completeness-content">
            <p :style="{ color: completenessColor }">{{ completeness }}</p>
            <p v-show="completenssTip.length != 0" :style="{ color: completenessColor }">
              {{ completenssTip }}
            </p>
          </div>
        </div>
      </el-popover>
    </div>
    <div class="cell-unit operation">
      <div class="cell-unit-operation-content">
        <button v-if="model.reportDeleted == '1'" class="cell-unit-operation-content-delete"
          @click="onDelete">删除</button>
        <cell-unit title="查看" :validIconSRC="previewValidSRC" :inValidIconSRC="previewInvalidSRC"
          :isValid="isShowButton" @onClick="onPreviewClick"></cell-unit>
        <div class="cell-unit-operation-content-slash"></div>
        <cell-unit title="下载" :validIconSRC="downloadValidSRC" :inValidIconSRC="downloadInvalidSRC"
          :isValid="isShowButton" @onClick="onDownloadClick"></cell-unit>
      </div>
    </div>
  </div>
</template>

<script>
import CellUnit from "@c/report/sleep-report/cell-unit.vue";
import { DateTool } from "@js/date-tool.js";
import { ReportPreviewTool } from "@js/report-preview-tool.js";
export default {
  components: {
    CellUnit,
  },

  data: function () {
    return {
      previewValidSRC: require("@imgs/report-preview.png"),
      previewInvalidSRC: require("@imgs/report-preview-gray.png"),
      downloadValidSRC: require("@imgs/report-download.png"),
      downloadInvalidSRC: require("@imgs/report-download-gray.png"),
      showBox: false
    };
  },

  props: {
    model: Object,
    isSelected: Boolean,
  },

  computed: {
    monitorTime() {
      var monitorTimeText = "开始入睡时间~起床时间：" + placeholderText(DateTool.millisecondTimestampToStr(this.model.startSleepTime, "M月D日 h:m")) + " ~ " + placeholderText(DateTool.millisecondTimestampToStr(this.model.getup, "M月D日 h:m"));
      return monitorTimeText;
    },
    sleepTime() {
      const totalMinutes = this.model.remTime + this.model.lightSleepTime + this.model.deepSleepTime
      const hour = parseInt(totalMinutes / 60)
      const minute = totalMinutes - hour * 60
      return `睡眠时长（TST）:` + `${hour}小时${minute}分钟`
    },
    isLessThan4Hours() {
      const distanceMillSeconds = this.model.getup - this.model.startSleepTime
      return distanceMillSeconds < 240 * 60 * 1000
    },
    isLessThan3Hours() {
      const totalMinutes = this.model.remTime + this.model.lightSleepTime + this.model.deepSleepTime
      const hour = parseInt(totalMinutes / 60)
      if (hour < 3) {
        return true
      } else {
        return false
      }
    },
    createTime() {
      var ccreateTimeText = "报告上传时间：" + placeholderText(DateTool.millisecondTimestampToStr(this.model.createDat, "Y-M-D h:m:s"))
      return ccreateTimeText
    },
    completeContent() {
      if (this.model.complete == "完整") {
        return "AHI：" + this.model.ahi
      } else {
        return this.model.complete
      }
    },
    userName() {
      return placeholderText(this.model.trueName)
    },

    recordDay: function () {
      return placeholderText(DateTool.millisecondTimestampToStr(this.model.recordDay, "Y-M-D h:m"));
    },

    isValid: function () {
      return this.model.complete == "完整";
    },


    isOxygenDeficiencyView: function () {

      return this.model.oxygenDeficiencyView == "1"
    },

    isShowButton: function () {

      if (this.isOxygenDeficiencyView) {

        if (this.model.complete == "完整" || this.model.complete == "缺少血氧") {
          return true
        } else {
          return false
        }
      } else {
        if (this.model.complete == "完整") {
          return true
        } else {
          return false
        }
      }
    },

    ahiInfo: function () {
      if (this.isValid) {
        return `${this.model.ahi}（${this.model.ahiLevel}）`;
      } else {
        return "-";
      }
    },

    ahiColor: function () {
      if (this.isValid) {
        const ahiLevel = this.model.ahiLevel;
        switch (ahiLevel) {
          case "正常":
            return "#6cc291";
          case "轻度":
            return "#666666";
          case "中度":
            return "#325FAC";
          default:
            return "#f45c50";
        }
      } else {
        return "#666666";
      }
    },

    completeness: function () {
      return this.isValid ? "是" : "无效报告";
    },

    completenessColor: function () {
      return this.isValid ? "#666666" : "#f45c50";
    },

    completenssTip: function () {
      return this.isValid ? "" : `（${this.model.complete}）`;
    },

    reportURL: function () {
      return REPORT_BASE_URL + this.model.reportUrl + ".pdf";
    },

    reportName: function () {
      return (
        this.model.trueName +
        DateTool.timestampToStr(this.model.recordDay, "M月D日 h：m")
      );
    },

    hospitalName() {
      return placeholderText(this.model.hospitalName)
    }
  },

  methods: {
    onMouseEnter() {
      // 开启 doShow()
      this.$refs.popover.doShow();
    },

    onMouseLeave() {
      this.$refs.popover.doClose();
    },

    onSelectClick: function () {
      this.$emit("onSelectClick", this.model.id);
    },

    onDelete() {
      this.$alert.show("报告操作", "是否要删除报告？", () => {
        this.$emit("onDeleteReport", this.model.id)
      });
    },

    onPreviewClick: function () {
      if (this.isShowButton) {
        ReportPreviewTool.previewReport(
          this.model.trueName,
          this.model.reportNumber,
          this.model.createDat
        );
      }
    },

    onDownloadClick: function () {
      if (this.isShowButton) {
        if (this.model.pdfUrl == null) {
          this.reportGenerateUrl()
        } else {
          ReportPreviewTool.downloadReport(
            this.model.trueName,
            this.model.pdfUrl,
            this.model.createDat
          );
        }
      }
    },

    async reportGenerateUrl() {
      try {
        const params = {
          reportId: this.model.id,
        }
        const response = await this.$api.reportGenerateUrl(params)
        this.model.pdfUrl = response.pdfUrl
        this.onDownloadClick()
      } catch (error) {
        this.$toast.showRed(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.cell {
  color: #666666;
  background-color: white;
  font-size: rem(14);
  line-height: rem(22);
  text-align: center;
  border-bottom: rem(1) solid #e8e8e8;
  width: 100%;
  height: rem(54);
  display: flex;

  &:hover {
    background-color: #f7f7f7;
  }

  &-unit {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &-completeness-content {
      line-height: rem(18);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-operation-content {
      display: flex;

      &-delete {
        color: rgb(244, 92, 80);
        margin-right: 20px;
      }

      &-slash {
        background-color: #e9e9e9;
        width: rem(1);
        height: rem(14);
        margin-left: rem(10);
        margin-right: rem(10);
      }
    }
  }

  .name {
    width: 7%;
  }

  .ahi {
    width: 9.5%;
  }

  .sn {
    width: 13%;
  }

  .create-time {
    width: 17%;
  }

  .organization {
    width: 20%;
  }

  .completeness {
    width: rem(160);
  }

  .completeness-out {
    width: 14%;
  }

  .operation {
    width: 19.5%;
  }
}

.selected {
  background-color: #e6f7ff;

  &:hover {
    background-color: #e6f7ff;
  }
}

.popbox {
  font-size: rem(12);

  color: #817889;

  &-sleepTime {
    margin-top: rem(3);
  }

  &-create {
    margin-top: rem(3);
  }

}
</style>